import { ReactElement } from 'react';
import { Route } from 'react-router-dom';
import { OAuth2GrantCallbackMode } from '../auth/utils/auth';
import LoginAndConfigureRedirector from './LoginAndConfigureRedirector';
import { CipLoginRedirector } from './CipLoginRedirector';
import { IAppRouterProps } from './RouterComponentSwitcher';
import { useFeatureFlagsContext } from '../contexts/FeatureFlagsContext';
import { getSuggestedAuthProvider } from '../hooks/useSuggestedAuthProvider';
import { PartnerType } from '../interfaces/Partner';
import { RegionType } from '../helpers/RegionType';

/* istanbul ignore next: No need to codecov*/
export const LoginRoutes = (props: IAppRouterProps): ReactElement[] => {
  const featureFlags = useFeatureFlagsContext();
  let routes: ReactElement[] = [];

  props.partnerRegionRouteConfigs.forEach((routeConfig) => {
    const partner = routeConfig.partner;
    const partnerRegion = routeConfig.partnerRegion;
    // Build path for the end-user to hit, e.g. /uber-pro/nl
    const authEntryPath = `/${partner}/${partnerRegion}/`;
    // Build path to continue web server flow, e.g. /auth/uber-pro/webserverflow
    const webServerFlowPath = `/auth/${partner}/${partnerRegion}/webserverflow`;
    // note: pageName is not given on the web server auth flow route, because we log the analytics
    // on the first implicit auth flow redirect. Please keep the pageName as 'Login'

    switch (getSuggestedAuthProvider(featureFlags, partner as PartnerType, partnerRegion as RegionType)) {
      case 'cip':
        routes = routes.concat([
          <Route key={`initiate-oauth-cip-flow-${partner}-${partnerRegion}`} path={authEntryPath} exact={true}>
            <CipLoginRedirector partner={partner} partnerRegion={partnerRegion} />
          </Route>,
        ]);
        break;

      case 'sf': {
        switch (routeConfig.grantType) {
          case OAuth2GrantCallbackMode.ImplicitFlow:
            routes = routes.concat([
              <Route key={`initiate-oauth-implicit-flow-${partner}-${partnerRegion}`} path={authEntryPath} exact={true}>
                <LoginAndConfigureRedirector
                  key={`initiate-oauth-implicit-flow-${partner}-${partnerRegion}`}
                  pageName={'Login'}
                  handleLogin={async () => {
                    window.location.replace(routeConfig.oAuthImplicitFlowStartURL!);
                  }}
                  partner={partner}
                  partnerRegion={partnerRegion}
                />
              </Route>,
            ]);
            break;
          case OAuth2GrantCallbackMode.AuthenticationCode:
            routes = routes.concat([
              <Route
                key={`initiate-oauth-web-server-flow-${partner}-${partnerRegion}`}
                path={authEntryPath}
                exact={true}
              >
                <LoginAndConfigureRedirector
                  key={`initiate-oauth-web-server-flow-${partner}-${partnerRegion}`}
                  handleLogin={async () => {
                    window.location.replace(routeConfig.oAuthWebServerFlowSartURL!);
                  }}
                  partner={partner}
                  pageName={'Login'}
                  partnerRegion={partnerRegion}
                />
              </Route>,
            ]);
            break;
          case OAuth2GrantCallbackMode.ImplicitFlowAuthenticationCodeHybrid:
            routes = routes.concat([
              <Route key={`initiate-oauth-implicit-flow-${partner}-${partnerRegion}`} path={authEntryPath} exact={true}>
                <LoginAndConfigureRedirector
                  key={`initiate-oauth-implicit-flow-${partner}-${partnerRegion}`}
                  pageName={'Login'}
                  handleLogin={async () => {
                    window.location.replace(routeConfig.oAuthImplicitFlowStartURL!);
                  }}
                  partner={partner}
                  partnerRegion={partnerRegion}
                />
              </Route>,
              <Route
                key={`initiate-oauth-web-server-flow-${partner}-${partnerRegion}`}
                path={webServerFlowPath}
                exact={true}
              >
                <LoginAndConfigureRedirector
                  key={`initiate-oauth-web-server-flow-${partner}-${partnerRegion}`}
                  handleLogin={async () => {
                    window.location.replace(routeConfig.oAuthWebServerFlowURL!);
                  }}
                  partner={partner}
                  partnerRegion={partnerRegion}
                  pageName={'Login - Webserver Flow'}
                />
              </Route>,
            ]);
            break;
        }
      }
    }
  });

  return routes;
};
