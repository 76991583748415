import { useEffect, useState } from 'react';

import { FlowOptions, FlowStepRuleAction } from '../flow-engine';
import history from '../../routing/BrowserHistory';
import { useAPIContext, APIContextActions } from '../../api/APIContext';
import { IGetUserResult, isGetUserResult } from '../../api/graphql/queries/getUser';
import { IUser } from '../../user/IUser';
import { usePersistenceContext } from '../../persistence/PersistenceContext';
import { useOAuthContext } from '../../auth/OAuthContext';
import { useApplicationContext } from '../../contexts/ApplicationContext';
import { RegionType } from '../../helpers/RegionType';
import { Persistence } from '../../persistence/Persistence';
import { getErrorMessage } from '../../helpers/errorMessage';
import { useSetAtom } from 'jotai';
import { loyaltyInfoAtom } from '../../store';

const _getUser = async (
  getUser: APIContextActions['getUser'],
  user: IUser,
  idToken: string,
  currentPartner: string,
  currentPartnerRegion: RegionType,
  currentLoyaltyType: string,
): Promise<IGetUserResult | undefined> => {
  return await getUser!(
    idToken!,
    currentPartner.toLowerCase(),
    currentLoyaltyType.toLowerCase(),
    currentPartnerRegion.toLowerCase(),
    user?.userId as string,
  );
};

export const useSyncUserData = (): string => {
  const { getUser } = useAPIContext();
  const setLoyaltyInfo = useSetAtom(loyaltyInfoAtom);

  const [loyaltyId, setLoyaltyId] = useState<string>('');
  const { user, idToken } = useOAuthContext();
  const { currentPartner, currentPartnerRegion, currentLoyaltyType } = useApplicationContext();

  const { setUserData } = usePersistenceContext();

  useEffect(() => {
    (async () => {
      setLoyaltyInfo({ loading: 'loading', loyaltyId: '', loyaltyBarcode: '' });
      try {
        const userResult = await _getUser(
          getUser,
          user!,
          idToken!,
          currentPartner,
          currentPartnerRegion,
          currentLoyaltyType,
        );
        const _loyaltyId = setUserData(userResult!);
        setLoyaltyId(_loyaltyId);

        setLoyaltyInfo({
          loyaltyId: userResult?.user?.loyaltyInfo?.loyaltyId || '',
          loyaltyBarcode: userResult?.user?.loyaltyInfo?.loyaltyIdBarCode || '',
          loading: 'completed',
        });
      } catch (e) {
        setLoyaltyInfo({ loading: 'error', loyaltyId: '', loyaltyBarcode: '' });
        throw e;
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loyaltyId;
};

export async function action(
  apiContext: APIContextActions,
  flowOptions: Partial<FlowOptions>,
): Promise<IGetUserResult | undefined> {
  try {
    const { user, idToken, currentPartner, currentPartnerRegion, currentLoyaltyType } = flowOptions;
    const result = await _getUser(
      apiContext['getUser'],
      user!,
      idToken!,
      currentPartner!,
      currentPartnerRegion!,
      currentLoyaltyType!,
    );
    return result;
  } catch (error: unknown) {
    const errorWithMessage = getErrorMessage(error);
    throw new Error(`Flow action failure: "${errorWithMessage.message}"`);
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const completion = (flowStepRuleAction: FlowStepRuleAction, result: any): void => {
  let loyaltyId: string | null;
  const isValidUser = isGetUserResult(result);
  if (isValidUser) {
    loyaltyId = flowStepRuleAction.feedback!(result);
  } else {
    loyaltyId = Persistence.getLoyaltyId();
  }

  // If the loyalty card number is in persistence...
  if (loyaltyId && loyaltyId.length > 0) {
    history.replace('/home');
  } else {
    history.replace('/welcome');
  }
};
