import { Fragment, ReactElement, MouseEvent } from 'react';
import { ListGroup, Container, Row, Col } from 'react-bootstrap';
import history from '../../routing/BrowserHistory';
import { useTranslation, Trans } from 'react-i18next';
import { staticConfig } from '../../config';
import { useApplicationContext } from '../../contexts/ApplicationContext';
import { getPartnerRegionConfig } from '../../helpers/PartnerRegionPropertySelector';
import { Region } from '../../interfaces/Region';

const Footer = (): ReactElement => {
  const { t } = useTranslation('footer');
  const ukLinks = useTranslation('tandcUK');
  const auLinks = useTranslation('tandcAU');
  const { currentPartner, currentPartnerRegion } = useApplicationContext();
  const tandcExternal: Record<string, string> = {
    'uber-prouk': ukLinks.t('tandcLink'),
  };
  const privacyPolicyExternal: Record<string, string> = {
    'uber-prouk': ukLinks.t('privacyPolicyLink'),
    'uber-proau': auLinks.t('privacyLink0'),
  };
  const privacyCollectionNoticeExternal: Record<string, string> = {
    'uber-proau': auLinks.t('privacyCollectionNoticeLink0'),
  };
  const faqExternal: Record<string, string> = {
    // 'uber-proau': auLinks.t('faqLink'),
  };

  /* istanbul ignore next: No need to codecov this redirection */
  const onViewFAQs = (path: string) => {
    history.push(path);
  };

  /* istanbul ignore next: No need to codecov this redirection */
  const onViewExternalLink = (path: string) => {
    window.open(path);
  };

  /* istanbul ignore next: No need to codecov this redirection */
  const onViewTermsAndConditions = (path: string) => {
    history.push(path);
  };

  /* istanbul ignore next: No need to codecov this redirection */
  const onViewPrivacyPolicy = (path: string) => {
    history.push(path);
  };

  const includesExternalLink = (path: string): boolean => {
    return path.includes('https');
  };

  const getFAQLink = (): string => {
    /* istanbul ignore next: No need to codecov */
    const partner = currentPartner.length > 0 ? currentPartner : '[partner not set]';
    const partnerRegion = currentPartnerRegion.length > 0 ? currentPartnerRegion : '[partnerRegion not set]';
    return faqExternal[partner + partnerRegion] !== undefined
      ? faqExternal[partner + partnerRegion]
      : '/' + partner + '/' + partnerRegion + '/faq';
  };

  const handleFAQ = (e?: MouseEvent) => {
    e?.preventDefault();

    const link = getFAQLink();
    if (includesExternalLink(link)) {
      onViewExternalLink(link);
    } else {
      onViewFAQs(link);
    }
  };

  const getTermsAndConditionsLink = (): string => {
    /* istanbul ignore next: No need to codecov */
    const partner = currentPartner.length > 0 ? currentPartner : '[partner not set]';
    /* istanbul ignore next: No need to codecov */
    const partnerRegion = currentPartnerRegion.length > 0 ? currentPartnerRegion : '[partnerRegion not set]';
    return tandcExternal[partner + partnerRegion] !== undefined
      ? tandcExternal[partner + partnerRegion]
      : '/' + partner + '/' + partnerRegion + '/terms/latest';
  };

  const handleViewTermsAndConditions = (e?: MouseEvent) => {
    e?.preventDefault();
    const link = getTermsAndConditionsLink();
    if (includesExternalLink(link)) {
      onViewExternalLink(link);
    } else {
      onViewTermsAndConditions(link);
    }
  };

  const getPrivacyPolicyLink = (): string => {
    /* istanbul ignore next: No need to codecov */
    const partner = currentPartner.length > 0 ? currentPartner : '[partner not set]';
    /* istanbul ignore next: No need to codecov */
    const partnerRegion = currentPartnerRegion.length > 0 ? currentPartnerRegion : '[partnerRegion not set]';
    return privacyPolicyExternal[partner + partnerRegion] !== undefined
      ? privacyPolicyExternal[partner + partnerRegion]
      : '/' + partner + '/' + partnerRegion + '/privacy/latest';
  };

  const getPrivacyCollectionNoticeLink = (): string => {
    /* istanbul ignore next: No need to codecov */
    const partner = currentPartner.length > 0 ? currentPartner : '[partner not set]';
    /* istanbul ignore next: No need to codecov */
    const partnerRegion = currentPartnerRegion.length > 0 ? currentPartnerRegion : '[partnerRegion not set]';
    return privacyCollectionNoticeExternal[partner + partnerRegion] !== undefined
      ? privacyCollectionNoticeExternal[partner + partnerRegion]
      : '/' + partner + '/' + partnerRegion + '/privacy/latest';
  };

  const handleViewPrivacyPolicy = (e?: MouseEvent) => {
    e?.preventDefault();
    const link = getPrivacyPolicyLink();
    if (includesExternalLink(link)) {
      onViewExternalLink(link);
    } else {
      onViewPrivacyPolicy(link);
    }
  };

  const handleViewPrivacyCollectionNotice = (e?: MouseEvent) => {
    e?.preventDefault();
    const link = getPrivacyCollectionNoticeLink();
    if (includesExternalLink(link)) {
      onViewExternalLink(link);
    } else {
      onViewPrivacyPolicy(link);
    }
  };

  let partnerRegionConfig: Region | null = null;

  if (currentPartner && currentPartnerRegion) {
    partnerRegionConfig = getPartnerRegionConfig(currentPartner, currentPartnerRegion);
  }

  const contactLinkExternal: Record<string, string> = {
    'uber-prouk': getContactDetails(partnerRegionConfig),
    'uber-proau': getContactDetails(partnerRegionConfig),
  };

  function getContactDetails(partnerRegionConfig: Region | null): string {
    return partnerRegionConfig?.contactEmail !== undefined ? partnerRegionConfig?.contactEmail : '';
  }

  function getContactLink(): string {
    if (contactLinkExternal[currentPartner + currentPartnerRegion] !== undefined) {
      return contactLinkExternal[currentPartner + currentPartnerRegion];
    } else {
      return partnerRegionConfig ? 'mailto:' + partnerRegionConfig.contactEmail : '';
    }
  }

  return (
    <Container data-testid="footer" className="footer">
      <Row>
        <Col className="mt-auto">
          <ListGroup variant="flush">
            <ListGroup.Item className="footer-text__heading" data-testid="footer-heading-container">
              {partnerRegionConfig ? partnerRegionConfig.companyName : <Fragment />}
            </ListGroup.Item>
            <ListGroup.Item className="footer-text__subheading" data-testid="footer-subheading-container">
              {partnerRegionConfig ? (
                t('copyright', {
                  startYear: partnerRegionConfig.copyright.startYear,
                  endYear: partnerRegionConfig.copyright.endYear,
                })
              ) : (
                <Fragment />
              )}
            </ListGroup.Item>
            {partnerRegionConfig ? (
              <ListGroup.Item className="footer-text__link" data-testid="footer-terms-and-conditions-container">
                <Trans i18nKey="footer:linkFAQ">
                  <a onClick={handleFAQ} href={getFAQLink()} data-testid="footer-faq-link">
                    FAQ link
                  </a>
                </Trans>
              </ListGroup.Item>
            ) : (
              <Fragment />
            )}
            {partnerRegionConfig ? (
              <ListGroup.Item className="footer-text__link" data-testid="footer-terms-and-conditions-container">
                <Trans i18nKey="footer:linkTermsAndConditions">
                  <a
                    onClick={handleViewTermsAndConditions}
                    href={getTermsAndConditionsLink()}
                    data-testid="footer-terms-and-conditions-link"
                  >
                    Some Terms and Conditions Text
                  </a>
                </Trans>
              </ListGroup.Item>
            ) : (
              <Fragment />
            )}
            {partnerRegionConfig ? (
              <ListGroup.Item className="footer-text__link" data-testid="footer-privacy-statement-container">
                <Trans i18nKey="footer:linkPrivacyStatement">
                  <a
                    onClick={handleViewPrivacyPolicy}
                    href={getPrivacyPolicyLink()}
                    data-testid="footer-privacy-statement-link"
                  >
                    Some Privacy Statement Text
                  </a>
                </Trans>
              </ListGroup.Item>
            ) : (
              <Fragment />
            )}
            {partnerRegionConfig && currentPartnerRegion === 'au' ? (
              <ListGroup.Item className="footer-text__link" data-testid="footer-privacy-collection-notice-container">
                <Trans i18nKey="footer:linkPrivacyCollectionNotice">
                  <a
                    onClick={handleViewPrivacyCollectionNotice}
                    href={getPrivacyCollectionNoticeLink()}
                    data-testid="footer-privacy-collection-notice-link"
                  >
                    Some Privacy Collection Notice Link Text
                  </a>
                </Trans>
              </ListGroup.Item>
            ) : (
              <Fragment />
            )}
            {partnerRegionConfig ? (
              <ListGroup.Item className="footer-text__link" data-testid="footer-contact-us-container">
                <Trans i18nKey="footer:linkContactUs">
                  <a
                    href={getContactLink()}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-testid="footer-contact-us-link"
                  >
                    Some Contact Us Text
                  </a>
                </Trans>
              </ListGroup.Item>
            ) : (
              <Fragment />
            )}
          </ListGroup>
        </Col>
      </Row>
      <Row>
        <Col className="footer-text__buildnumber" data-testid="footer-build-number-container">
          {staticConfig.system.buildNumber}
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
