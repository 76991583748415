import { useLayoutEffect, ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import ErrorModal, { refreshPage } from '../ErrorModal/ErrorModal';

import { useAppState } from './useAppState';
import KillSwitch from '../KillSwitch/KillSwitch';

import RouterComponentSwitcher, { IAppRouterProps } from '../../routing/RouterComponentSwitcher';

const App = (props: IAppRouterProps): ReactElement => {
  const location = useLocation();
  const { showFooter, setShowFooter, embedded } = useAppState();

  useLayoutEffect(() => {
    setShowFooter(true);
  }, [location.pathname, setShowFooter]);

  return (
    <>
      <div className="body-wrap">
        {!embedded && <Header />}
        <KillSwitch>
          <RouterComponentSwitcher
            partnerRegionRouteConfigs={props.partnerRegionRouteConfigs}
            onErrorCallback={props.onErrorCallback}
          />
        </KillSwitch>
      </div>
      {showFooter ? <Footer /> : null}
      <ErrorModal onRefresh={refreshPage}></ErrorModal>
    </>
  );
};

export default App;
