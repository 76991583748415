import { FlowStepName, FlowStepActionFunction, FlowStepActionFunctionVariadic } from './flow-engine';
import { action as consentAction } from './actions/consent-action';
import { action as lastLocationAction } from './actions/last-location-action';
import { action as welcomeAction } from './actions/welcome-action';
import { action as homeAction } from './actions/home-action';
import { action as syncUserAction } from './actions/sync-user-action';
import { action as endOfRedemptionPeriodAction } from './actions/end-of-redemption-period-action';
import { action as registrationAction } from './actions/registration-action';
import { action as createLoyaltyAction } from './actions/create-loyalty-action';

export default function getAction(step: FlowStepName): FlowStepActionFunction | FlowStepActionFunctionVariadic {
  const actions: Record<FlowStepName, FlowStepActionFunction | FlowStepActionFunctionVariadic> = {
    consent: consentAction,
    'last-location': lastLocationAction,
    welcome: welcomeAction,
    home: homeAction,
    'sync-user-then-to-welcome-or-home': syncUserAction,
    'end-of-redemption-period': endOfRedemptionPeriodAction,
    registration: registrationAction,
    'create-loyalty': createLoyaltyAction,
  };
  return actions[step];
}
