import { useCallback } from 'react';

import { useAPIContext } from '../api/APIContext';
import { IBFFClientResult, IGraphQLErrors } from '../api/graphql/AppSyncClient';
import history from '../routing/BrowserHistory';
import { useApplicationContext } from '../contexts/ApplicationContext';
import { useOAuthContext } from '../auth/OAuthContext';
import { usePersistenceContext } from '../persistence/PersistenceContext';
import { LinkLoyaltyInfoInput } from '../api/graphql/mutations/linkLoyaltyInfo';
import { ILoyaltyInfo } from '../user/ILoyaltyInfo';

export interface CreateLoyaltyInfo {
  idToken: string;
  loyaltyId: string;
  loyaltyType: string;
  partnerType: string;
  partnerLevelId: string;
  partnerRegion: string;
  linkLoyaltyInfo: (idToken: string, input: LinkLoyaltyInfoInput) => Promise<IBFFClientResult>;
  pollLoyaltyInfo: any;
}

export async function createLoyaltyWithPolling({
  idToken,
  loyaltyId,
  loyaltyType,
  partnerType,
  partnerLevelId,
  partnerRegion,
  linkLoyaltyInfo,
  pollLoyaltyInfo,
}: CreateLoyaltyInfo): Promise<ILoyaltyInfo | undefined> {
  try {
    const linkLoyaltyInfoInput = {
      loyaltyId,
      loyaltyType,
      partnerType,
      partnerLevelId,
      partnerRegion,
    };

    // Save Loyalty ID in persistence so that we're not accidentally forwarded to the
    // welcome page
    const loyaltyInfo = (await linkLoyaltyInfo!(idToken!, linkLoyaltyInfoInput)).data;
    const _loyaltyId = loyaltyInfo?.linkLoyaltyInfo?.loyaltyId as string;
    const _loyaltyBarcode = loyaltyInfo?.linkLoyaltyInfo?.loyaltyBarcode as string;
    return {
      loyaltyId: _loyaltyId,
      loading: 'completed',
      loyaltyBarcode: _loyaltyBarcode,
    };
  } catch (err) {
    const graphQLError = err as IGraphQLErrors;

    const isTimeout = !!graphQLError.message && graphQLError.message.indexOf('Endpoint request timed out') !== -1;

    if (isTimeout) {
      // graphQLError.networkError.statusCode === 408
      const loyaltyInfo = await pollLoyaltyInfo(idToken!, partnerType, loyaltyType, partnerRegion);
      if (loyaltyInfo?.loyaltyId) {
        return {
          loyaltyId: loyaltyInfo.loyaltyId!,
          loading: 'completed',
          loyaltyBarcode: loyaltyInfo.loyaltyIdBarCode!,
        };
      }
    }

    if (
      graphQLError.graphQLErrors &&
      graphQLError.graphQLErrors.length > 0 &&
      (graphQLError.message ===
        'GraphQL error: "The mobile phone number is already registered on BP Me Rewards(FIS)"' ||
        graphQLError.message === 'GraphQL error: "The SFID is already registered on BP Me Rewards(FIS)"')
    ) {
      history.push('/welcome?alreadyRegistered=true');
    } else {
      history.push(`/500?${graphQLError.message}`);
    }
    return;
  }
}

export const useSetLoyaltyInfoWithPolling = () => {
  const { idToken } = useOAuthContext();
  const { partnerLevelId, setLoyaltyInfo } = usePersistenceContext();
  const { linkLoyaltyInfo, pollLoyaltyInfo } = useAPIContext();
  const { currentLoyaltyType, currentPartner, currentPartnerRegion } = useApplicationContext();

  const callback = useCallback(
    async (loyaltyId: string): Promise<boolean> => {
      const res = await createLoyaltyWithPolling({
        idToken: idToken || '',
        loyaltyId,
        loyaltyType: currentLoyaltyType.toLowerCase(),
        partnerType: currentPartner.toLowerCase(),
        partnerLevelId,
        partnerRegion: currentPartnerRegion.toLowerCase(),
        linkLoyaltyInfo,
        pollLoyaltyInfo,
      });

      setLoyaltyInfo({
        loading: 'completed',
        loyaltyId: res?.loyaltyId || '',
        loyaltyBarcode: res?.loyaltyBarcode || '',
      });

      return !!res;
    },
    [
      currentLoyaltyType,
      currentPartner,
      currentPartnerRegion,
      idToken,
      linkLoyaltyInfo,
      partnerLevelId,
      pollLoyaltyInfo,
      setLoyaltyInfo,
    ],
  );

  return [callback];
};
