import CryptoJS from 'crypto-js';
import Base64 from 'crypto-js/enc-base64';

import { Persistence } from '../../persistence/Persistence';
import { random } from '../../utils/string';

const base64Encode = (str: CryptoJS.lib.WordArray) => {
  return Base64.stringify(str).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
};

function generateCodeVerifier(): string {
  return random(128);
}

function generateCodeChallenge(_codeVerifier: string): string {
  const codeChallenge = base64Encode(CryptoJS.SHA256(_codeVerifier));
  return codeChallenge;
}

/**
 *
 * Tuple: represents code challenge and code verifier.
 */
type PKCEChallengeVerifier = [string, string];

/**
 *
 * Generates a code challenge and code verifier.
 * @returns PKCEChallengeVerifier (tuple [string, string] challenge and verifier)
 */

export function getCodeVerifierAndCodeChallenge(storeKeySuffix: string): PKCEChallengeVerifier {
  let codeChallenge = Persistence.getCodeChallenge(storeKeySuffix);
  let codeVerifier = Persistence.getCodeVerifier(storeKeySuffix);
  if (!codeChallenge || codeChallenge.length === 0) {
    codeVerifier = generateCodeVerifier();
    codeChallenge = generateCodeChallenge(codeVerifier);
    Persistence.setCodeVerifier(codeVerifier, storeKeySuffix);
    Persistence.setCodeChallenge(codeChallenge, storeKeySuffix);
  }

  return [codeChallenge, codeVerifier!];
}
