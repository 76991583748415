import { RouteConfig } from '../../routing/RouterComponentSwitcher';
import { salesforceRouteConfig } from './salesforce-auth-route-config';

/**
 * Generates a routing config that maps partner and region to the corresponding IDP.
 * e.g https://reward.bp.com/{partner}/{region} to https://{idp-domain}/oauth2/authorize
 * when partner = uber-pro, region = nl and idp = Salesforce
 * https://reward.bp.com/uper-pro/NL to https://idp.bp.com/oauth2/authorize
 * @returns RouteConfig
 */
export default function getRouteConfig(
  regionIDP: string,
  regionGrantType: string,
  partnerType: string,
  partnerRegion: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  idpConfig: any,
): RouteConfig | undefined {
  const authRouteFactory = new Map<string, RouteConfig>([
    ['Salesforce', salesforceRouteConfig(idpConfig, regionGrantType, partnerType, partnerRegion)],
  ]);

  const routeConfig = authRouteFactory.get(regionIDP);

  return routeConfig;
}
