import './styles/styles.scss';
import React, { ReactElement } from 'react';
import { createRoot } from 'react-dom/client';
import { Router } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { HelmetProvider as DocumentHeadProvider } from 'react-helmet-async';

import App from './components/app/App';
import { APIContextProvider } from './api/APIContext';
import { OAuthContextProvider } from './auth/OAuthContext';
import history from './routing/BrowserHistory';
import { isConfiguredCorrectly, staticConfig } from './config';
import { isDev } from './helpers/EnvironmentDetect';
import { ApplicationContextProvider } from './contexts/ApplicationContext';
import { PersistenceContextProvider } from './persistence/PersistenceContext';
import PartnerRegionRouteConfigs from './partners/PartnerAuthRoutes/configuration';
import DocumentHead from './components/DocumentHead/DocumentHead';
import AnalyticsProvider from './analytics/AnalyticsProvider';
import { IGraphQLErrors } from './api/graphql/AppSyncClient';
import { AppSyncLoggerConfiguration, setupMarvelLogger } from '@marvel-common/bp-digital-logging';
import packageJson from '../package.json';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import { FeatureFlagsContextProvider } from './contexts/FeatureFlagsContext';

// Turns 'https://api.dev-reward.bp.com/graphql into
// 'api.dev-reward.bp.com'
const hostName = staticConfig.appsync.endpoint.slice(8, -8);

const appSyncLoggerConfiguration: AppSyncLoggerConfiguration = {
  host: hostName,
  xApiKey: staticConfig.appsync.apiKey,
  mutationName: 'marvelLogging',
  logLevel: 'warn',
};

setupMarvelLogger(
  {
    applicationName: 'Marvel',
    serviceName: 'marvel-web',
    componentName: packageJson.name,
  },
  appSyncLoggerConfiguration,
);

// eslint-disable-next-line @typescript-eslint/no-empty-function
console.log = function () {};

const onErrorCallback = (result: any): void => {
  const errors = result.errors as IGraphQLErrors;
  if (errors?.graphQLErrors > 0) {
    history.push(`/500?${errors.graphQLErrors?.message}`);
  } else if (errors?.networkError) {
    const statusCode = errors?.networkError.statusCode;
    history.push(`/${statusCode}?${errors?.message}`);
  } else {
    // Doesn't follow standard format of graphql errors, but we should still redirect
    // to an error page, as this is an error after all...
    history.push(`/500?${result}`);
  }
};

const runSetup = (): void => {
  const result = isConfiguredCorrectly();
  if (isDev && result[0] === false) {
    const missingConfigSettings = result[1];
    history.push('418?result=' + missingConfigSettings);
  }
};

const RootRender = (): ReactElement => {
  runSetup();

  return (
    <ErrorBoundary>
      <DocumentHeadProvider>
        <FeatureFlagsContextProvider>
          <ApplicationContextProvider>
            <APIContextProvider>
              <React.Fragment>
                <DocumentHead />
                <AnalyticsProvider history={history}>
                  <OAuthContextProvider redirectToPath={'/landing'}>
                    <PersistenceContextProvider>
                      <Router history={history}>
                        <LastLocationProvider>
                          <App
                            partnerRegionRouteConfigs={PartnerRegionRouteConfigs}
                            onErrorCallback={onErrorCallback}
                          />
                        </LastLocationProvider>
                      </Router>
                    </PersistenceContextProvider>
                  </OAuthContextProvider>
                </AnalyticsProvider>
              </React.Fragment>
            </APIContextProvider>
          </ApplicationContextProvider>
        </FeatureFlagsContextProvider>
      </DocumentHeadProvider>
    </ErrorBoundary>
  );
};

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<RootRender />);
