import validatePhonePasscodeMutation from 'graphql-tag';

export default validatePhonePasscodeMutation`
mutation validatePhonePasscode($phoneInput: PhoneInput!, $passcode: String!) {
    validatePhonePasscode(phoneInput: $phoneInput, passcode: $passcode) {
      countryCode
      phoneNumber
    }
  }
`;
