import LoginAndConfigureRedirector from './LoginAndConfigureRedirector';
import { Config, TokenManager } from '@forgerock/javascript-sdk';
import { getPartnerRegionConfig } from '../helpers/PartnerRegionPropertySelector';

interface ICipLoginRedirectorProps {
  partner: string;
  partnerRegion: string;
}

export const CipLoginRedirector = ({ partner, partnerRegion }: ICipLoginRedirectorProps) => {
  const { cipConfig } = getPartnerRegionConfig(partner, partnerRegion);

  if (!cipConfig) {
    throw new Error(`CIP config not found for ${partner} and ${partnerRegion}.`);
  }

  return (
    <LoginAndConfigureRedirector
      key={`initiate-oauth-web-server-flow-${partner}-${partnerRegion}`}
      handleLogin={async () => {
        Config.set(cipConfig);
        // Force refesh CIP tokens
        await TokenManager.getTokens({
          forceRenew: true,
          login: 'redirect',
        });
      }}
      partner={partner}
      pageName={'Login'}
      partnerRegion={partnerRegion}
    />
  );
};
