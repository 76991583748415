import { RouteConfig } from '../../routing/RouterComponentSwitcher';
import { staticConfig } from '../../config';
import { SalesforceIdpConfig } from '../../interfaces/Region';
import getRouteConfig from './auth-route-config-factory';

const PartnerRegionRouteConfigs: RouteConfig[] = [];

staticConfig.partners.forEach((partner) => {
  partner.regions.forEach((region) => {
    const idpConfig: SalesforceIdpConfig = region.idpConfig as SalesforceIdpConfig;
    const routeConfig = getRouteConfig(
      region.idp,
      region.idpGrantType,
      partner.partnerType,
      region.regionName,
      idpConfig,
    );
    PartnerRegionRouteConfigs.push(routeConfig!);
  });
});

export default PartnerRegionRouteConfigs;
