/* eslint-disable */
import { useState, useEffect, useContext, useReducer, ReactElement, createContext } from 'react';
import axios from 'axios';
import qs from 'qs';
import { TokenManager, Config, UserManager } from '@forgerock/javascript-sdk';

import history from '../routing/BrowserHistory';
import { CipUserDto, IUser } from '../user/IUser';
import UserReducer, { UserMutationAction } from './UserReducer';
import {
  implicitFlowCallbackResult,
  authenticationCodeCallbackResult,
  saveUserHashedId,
  isSameUser,
  getOAuth2CallbackMode,
  OAuth2GrantCallbackMode,
  parseUserFromIdToken,
} from './utils/auth';
import { Persistence } from '../persistence/Persistence';
import { useAPIContext } from '../api/APIContext';
import { useApplicationContext } from '../contexts/ApplicationContext';
import { getPartnerRegionConfig } from '../helpers/PartnerRegionPropertySelector';
import { SalesforceIdpConfig } from '../interfaces/Region';
import { CIPJWTPayload, decodeToken } from '../helpers/JWT';
import { isAutomationTesting } from '../helpers/BrowserDetect';
import { useSuggestedAuthProvider } from '../hooks/useSuggestedAuthProvider';
import { useWindowLocation } from '../hooks/useWindowLocation';
import { mapCipUser } from '../utils/user';

type AuthTokenResponse = {
  access_token?: string;
  sfdc_community_url?: string;
  sfdc_community_id?: string;
  signature?: string;
  scope?: string;
  id_token?: string;
  instance_url?: string;
  id?: string;
  token_type?: string;
  issued_at?: string;
  refresh_token?: string;
};

async function _exchangeAuthorizationCode(
  code: string,
  config: SalesforceIdpConfig,
  regionName: string,
): Promise<AuthTokenResponse> {
  const encodeUrl = encodeURIComponent(`${config.idpDomain}${config.tokenUrl}`);
  // const authCodeURL = decodeURIComponent(encodeUrl);
  const params = {
    code: code,
    grant_type: 'authorization_code',
    client_id: `${config.clientId}`,
    redirect_uri: `${config.redirectUrl}`,
  };

  let data = qs.stringify(params, { encode: false });

  if (config.idpUsePKCE) {
    const codeVerifier = Persistence.getCodeVerifier(regionName);
    Persistence.removeCodeVerifier(regionName);
    Persistence.removeCodeChallenge(regionName);
    const params = {
      code: code,
      grant_type: 'authorization_code',
      client_id: `${config.clientId}`,
      redirect_uri: `${config.redirectUrl}`,
      code_verifier: codeVerifier,
    };
    data = qs.stringify(params, { encode: false });
  }

  return new Promise<AuthTokenResponse>((resolve, reject) => {
    axios.post(decodeURIComponent(encodeUrl), data).then(
      (response) => {
        resolve(response.data as AuthTokenResponse);
      },
      (error) => {
        reject(error);
      },
    );
  });
}

function saveUser(u: IUser): IUser {
  if (u!.userId) Persistence.setStorageEncryptionKey(u!.userId);
  if (isSameUser(u!.userId as string) === false) {
    Persistence.resetUserData();
  }

  saveUserHashedId(u!.userId as string);

  return u;
}

export type OAuthContextResult = {
  success: boolean;
  failure: boolean;
  error?: string;
};

export interface AuthState {
  readonly isAuthenticated: boolean;
  readonly isAuthenticating: boolean;
  readonly idToken?: string;
  readonly accessToken?: string;
  readonly user?: IUser;
  readonly audience?: string;
  dispatch?: ((action: UserMutationAction) => void) | undefined;
}

export interface OAuthContextProviderOptions {
  children?: ReactElement;
  redirectToPath: string;
}

const initialState: AuthState = {
  isAuthenticated: false,
  isAuthenticating: true,
  idToken: undefined,
  accessToken: undefined,
  user: undefined,
  audience: undefined,
};

export const OAuthContext = createContext<AuthState>(initialState);

export const useOAuthContext = (): AuthState => useContext(OAuthContext);

export const OAuthContextProvider = ({ children, redirectToPath }: OAuthContextProviderOptions): JSX.Element => {
  const location = useWindowLocation();
  const [, dispatch] = useReducer(UserReducer, initialState);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [idToken, setIdToken] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [audience, setAudience] = useState('');
  const [user, setUser] = useState<IUser>();
  const provider = useSuggestedAuthProvider();

  const { currentPartner, currentPartnerRegion, currentLoyaltyType } = useApplicationContext();
  const { saveUserTokens, saveUserTokensV2, getCipUser } = useAPIContext();

  useEffect(() => {
    (async () => {
      if (!currentPartner || !currentPartnerRegion) {
        return;
      }
      const partnerRegionConfig = getPartnerRegionConfig(currentPartner, currentPartnerRegion);

      setIsAuthenticated(false);
      setIsAuthenticating(false);

      const callbackMode = getOAuth2CallbackMode(location, partnerRegionConfig.idpHybridMode);

      switch (callbackMode) {
        case OAuth2GrantCallbackMode.CipFlow:
          {
            try {
              // Even if requested provider is CIP don't use it without a config.
              if (provider !== 'cip' || !partnerRegionConfig.cipConfig) {
                throw new Error('Cannot use this provider');
              }

              Config.set(partnerRegionConfig.cipConfig);

              // Check URL for query parameters
              const url = new URL(location.toString());
              const params = url.searchParams;
              const code = params.get('code');
              const state = params.get('state');

              if (!code || !state) {
                throw new Error('No code and state found in the URL.');
              }

              // Get currently logged in user
              const tokens = await TokenManager.getTokens({
                query: { code: code, state: state },
              });

              if (!tokens || !tokens.idToken || !tokens.accessToken || !tokens.refreshToken) {
                throw new Error('CIP tokens not found.');
              }

              setAccessToken(tokens.accessToken);

              setIdToken(tokens.idToken);

              const decodedToken = decodeToken<CIPJWTPayload>(tokens.accessToken);

              if (!decodedToken) {
                throw new Error('Cannot decode CIP access token.');
              }
              setAudience(decodedToken!.aud);

              const cipUser = (await UserManager.getCurrentUser()) as CipUserDto;

              await saveUserTokensV2(
                tokens.idToken,
                tokens.refreshToken,
                decodedToken.socialAccessToken,
                decodedToken.socialRefreshToken,
              );
              const userData = await getCipUser(
                tokens.idToken!,
                currentPartner,
                currentLoyaltyType,
                currentPartnerRegion,
                decodedToken?.external_id!,
              );
              const mappedUser = mapCipUser(userData.userPii, userData.user, cipUser);
              setUser(saveUser(mappedUser));

              setIsAuthenticated(true);
              history.replace(redirectToPath);
            } catch (error) {
              history.replace('/500?result=' + encodeURIComponent('Failed to login'));
            }
          }
          break;

        case OAuth2GrantCallbackMode.ImplicitFlow:
        case OAuth2GrantCallbackMode.ImplicitFlowHybrid:
          {
            const result = await implicitFlowCallbackResult(location);

            if (result.error) {
              history.replace(`/401?error=${result.error}`);
              return;
            } else {
              setIdToken(result.idToken!);
              setAccessToken(result.accessToken!);

              history.push(redirectToPath);
            }
          }
          break;
        case OAuth2GrantCallbackMode.AuthenticationCode:
        case OAuth2GrantCallbackMode.AuthenticationCodeHybrid:
          {
            const result = authenticationCodeCallbackResult(location);

            // Remove ID token from local storage as it's a security risk
            // This is a work-around for the hybrid flow. Once Auth_Code flow
            // is working the this will be removed.
            Persistence.removeStateEnc();
            try {
              const codeResponse = await _exchangeAuthorizationCode(
                result.code!,
                partnerRegionConfig.idpConfig as SalesforceIdpConfig,
                partnerRegionConfig.regionName,
              );
              const encodeResponse = encodeURIComponent(JSON.stringify(codeResponse));

              await saveUserTokens!(
                (codeResponse ?? JSON.parse(decodeURIComponent(encodeResponse))).id_token as string,
                (codeResponse ?? JSON.parse(decodeURIComponent(encodeResponse))).refresh_token as string,
                (codeResponse ?? JSON.parse(decodeURIComponent(encodeResponse))).access_token as string,
              );

              if (isAutomationTesting()) {
                Persistence.__storeTokensForAutomationTestingOnly(
                  (codeResponse ?? JSON.parse(decodeURIComponent(encodeResponse))).access_token!,
                  (codeResponse ?? JSON.parse(decodeURIComponent(encodeResponse))).id_token!,
                );
              }

              try {
                // Keep ID token in memory - NOT the same as local storage
                setIdToken((codeResponse ?? JSON.parse(decodeURIComponent(encodeResponse)))!.id_token!);

                const decodedToken = decodeToken(
                  (codeResponse ?? JSON.parse(decodeURIComponent(encodeResponse)))!.id_token!,
                );
                setAudience(decodedToken!.aud);

                setUser(
                  saveUser(
                    parseUserFromIdToken((codeResponse ?? JSON.parse(decodeURIComponent(encodeResponse)))!.id_token!),
                  ),
                );
              } catch (error) {
                history.replace(`/401?error=${error}`);
                return;
              }
              setIsAuthenticated(true);
              history.replace(redirectToPath);
            } catch (error) {
              history.replace(`/401?error=${error}`);
              return;
            }
          }
          break;

        default:
          break;
      }
    })();
  }, [redirectToPath, location]);

  return (
    <OAuthContext.Provider
      value={{
        isAuthenticated,
        isAuthenticating,
        idToken,
        accessToken,
        user,
        audience,
        dispatch,
      }}
    >
      {children}
    </OAuthContext.Provider>
  );
};
