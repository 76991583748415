import urlencode from 'urlencode';

import { OAuth2GrantCallbackMode } from '../../auth/utils/auth';
import { getCodeVerifierAndCodeChallenge } from '../../auth/utils/pkce';
import { RouteConfig } from '../../routing/RouterComponentSwitcher';
import { random } from '../../utils/string';
import { SalesforceIdpConfig } from '../../interfaces/Region';

export const salesforceRouteConfig = (
  salesforceIdpConfig: SalesforceIdpConfig,
  idpGrantType: string,
  partnerType: string,
  partnerRegion: string,
): RouteConfig => {
  return {
    partner: partnerType,
    partnerRegion: partnerRegion,
    oAuthImplicitFlowURL: implicitFlowURL(
      salesforceIdpConfig.authorizeUrl,
      salesforceIdpConfig.authorizeResponseAndScopes,
      salesforceIdpConfig.clientId,
      salesforceIdpConfig.redirectUrl,
    ),
    oAuthImplicitFlowStartURL: implicitFlowStartURL(
      salesforceIdpConfig.idpDomain,
      salesforceIdpConfig.authorizeSsoUrl,
      salesforceIdpConfig.reidpId,
      salesforceIdpConfig.authorizeUrl,
      salesforceIdpConfig.authorizeResponseAndScopes,
      salesforceIdpConfig.clientId,
      salesforceIdpConfig.redirectUrl,
    ),
    oAuthWebServerFlowURL: authCodeFlowURL(
      salesforceIdpConfig.idpDomain,
      salesforceIdpConfig.authorizeUrl,
      salesforceIdpConfig.clientId,
      salesforceIdpConfig.redirectUrl,
    ),
    oAuthWebServerFlowSartURL: authCodeFlowStartURL(
      salesforceIdpConfig.idpDomain,
      salesforceIdpConfig.authorizeSsoUrl,
      salesforceIdpConfig.reidpId,
      salesforceIdpConfig.authorizeUrl,
      salesforceIdpConfig.clientId,
      salesforceIdpConfig.redirectUrl,
      salesforceIdpConfig.idpUsePKCE,
      partnerRegion,
    ),
    grantType: (OAuth2GrantCallbackMode as any)[idpGrantType],
  };
};

function implicitFlowURL(
  authoriseURL: string,
  authorizeResponseAndScopes: string,
  clientId: string,
  redirectUrl: string,
) {
  return `${authoriseURL}?${authorizeResponseAndScopes}&client_id=${clientId}&redirect_uri=${redirectUrl}&nonce=${random(
    10,
  )}`;
}

function implicitFlowStartURL(
  idpDomain: string,
  authorizeSsoUrl: string,
  reidpId: string,
  authoriseURL: string,
  authorizeResponseAndScopes: string,
  clientId: string,
  redirectUrl: string,
) {
  return `${idpDomain}${authorizeSsoUrl}/REIDP_${reidpId}?startURL=${urlencode.encode(
    `${authoriseURL}?${authorizeResponseAndScopes}&client_id=${clientId}&redirect_uri=${redirectUrl}&nonce=${random(
      10,
    )}`,
  )}`;
}

function authCodeFlowURL(idpDomain: string, authoriseURL: string, clientId: string, redirectUrl: string) {
  return `${idpDomain}${authoriseURL}?client_id=${clientId}&redirect_uri=${redirectUrl}&response_type=code`;
}

function authCodeFlowStartURL(
  idpDomain: string,
  authorizeSsoUrl: string,
  reidpId: string,
  authoriseURL: string,
  clientId: string,
  redirectUrl: string,
  usePKCE: boolean,
  partnerRegion: string,
) {
  let url = `${idpDomain}${authorizeSsoUrl}/REIDP_${reidpId}?startURL=${urlencode.encode(
    `${authoriseURL}?client_id=${clientId}&redirect_uri=${redirectUrl}&response_type=code`,
  )}`;

  if (usePKCE) {
    const cv = getCodeVerifierAndCodeChallenge(partnerRegion);
    url = `${url}${urlencode.encode(`&code_challenge=`)}${cv[0]}`;
  }

  return url;
}
