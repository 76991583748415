import { Persistence } from '../../persistence/Persistence';
import { FlowStepRuleFunctionVariadic } from '../flow-engine';

export const rule: FlowStepRuleFunctionVariadic = async function rule(_apiContext, flowOptions) {
  const { currentPartnerRegion, user } = flowOptions;

  if (currentPartnerRegion && !['au', 'us', 'uk'].includes(currentPartnerRegion)) {
    return false;
  }

  const loyaltyId = user?.loyaltyInfo?.loyaltyId || Persistence.getLoyaltyId();
  if (user?.phoneNumberVerified && user?.phone && !loyaltyId) {
    return true;
  }

  return false;
};
