/* eslint-disable import/no-unresolved */
import { ReactElement, useEffect } from 'react';

import history from '../routing/BrowserHistory';
import { useAPIContext } from '../api/APIContext';
import ActivityIndicator, { ActivityIndicatorType } from '../components/ActivityIndicator/ActivityIndicator';
import { useAppState } from '../components/app/useAppState';
import { FlowOptions, runFlowEngine } from '../flowEngine/flow-engine';
import { useOAuthContext } from '../auth/OAuthContext';
import { useApplicationContext } from '../contexts/ApplicationContext';
import { getPartnerRegionConfig } from '../helpers/PartnerRegionPropertySelector';
import { usePersistenceContext } from '../persistence/PersistenceContext';
import { useSuggestedAuthProvider } from '../hooks/useSuggestedAuthProvider';

import { initializeAppSettings } from '../helpers/InitializeAppSettings';
import { programAtom } from '../store';
import { useAtomValue } from 'jotai';

const LandingRedirector = (): ReactElement => {
  const apiContext = useAPIContext();
  const { setShowFooter } = useAppState();
  const { user, idToken } = useOAuthContext();
  const { currentPartner, currentPartnerRegion, currentLoyaltyType, currentAppSettings, setCurrentAppSettings } =
    useApplicationContext();
  const suggestedAuthProvider = useSuggestedAuthProvider();

  const currentProgram = useAtomValue(programAtom);

  const { setUserData } = usePersistenceContext();

  useEffect(() => {
    setShowFooter(false);

    async function sync() {
      const partnerRegionConfig = getPartnerRegionConfig(currentPartner, currentPartnerRegion);
      const flowOptions: Partial<FlowOptions> = {
        user,
        region: partnerRegionConfig,
        idToken,
        currentPartner,
        currentProgram,
        currentPartnerRegion,
        currentLoyaltyType,
        currentAppSettings,
        setCurrentAppSettings,
        suggestedAuthProvider,
      };
      try {
        await initializeAppSettings(apiContext, {
          partner: currentPartner,
          region: currentPartnerRegion,
          setCurrentAppSettings: setCurrentAppSettings,
        });
        await runFlowEngine(apiContext, flowOptions, setUserData);
      } catch (error) {
        history.push('/500?result=' + encodeURIComponent(error as any));
      }
    }

    sync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ActivityIndicator type={ActivityIndicatorType.FullPage} />;
};

export default LandingRedirector;
