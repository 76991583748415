import { staticConfig } from '../config';
import { Region } from '../interfaces/Region';
import { RegionType } from './RegionType';

/***********************************************
 * INTERNALS - DO NOT EXPOSE
 ***********************************************/

let supportedPartnerRegionCombinationRegex = '';

// Return regex of the form:
// ((uber-pro/us)|(uber-pro/nl)|(lyft/us)) etc.
function getSupportedPartnerRegionCombinationsAsRegex(): RegExp {
  if (!supportedPartnerRegionCombinationRegex) {
    const supportedPartners = getSupportedPartners();
    supportedPartners.forEach((supportedPartner) => {
      const supportedPartnerRegions = getSupportedRegions(supportedPartner);
      supportedPartnerRegions.forEach((supportedPartnerRegion) => {
        supportedPartnerRegionCombinationRegex += `(${supportedPartner}/${supportedPartnerRegion})|`;
      });
    });
    // Strip off the trailing "|"
    supportedPartnerRegionCombinationRegex = supportedPartnerRegionCombinationRegex.slice(0, -1);
  }
  return new RegExp(supportedPartnerRegionCombinationRegex, 'gi');
}

/***********************************************
 * END OF INTERNALS
 ***********************************************/

/**
 * Returns region configuration for selected partner/partnerRegion
 */
export function getPartnerRegionConfig(selectedPartner: string, selectedPartnerRegion: string): Region {
  const partnerData = staticConfig.partners.find((partner) => partner.partnerType === selectedPartner);
  const regionData = partnerData?.regions.find(
    (region) => region.regionName.toLowerCase() === (!selectedPartnerRegion ? '' : selectedPartnerRegion.toLowerCase()),
  );

  return regionData as Region;
}

/**
 * Returns default language based on selected partner/partnerRegion
 * TODO take into account user preferences
 */
export function getDefaultLanguage(selectedPartner: string, selectedPartnerRegion: string): string {
  const partnerData = staticConfig.partners.find((partner) => partner.partnerType === selectedPartner);
  const regionData = partnerData?.regions.find(
    (region) => region.regionName.toLowerCase() === (!selectedPartnerRegion ? '' : selectedPartnerRegion.toLowerCase()),
  );
  return regionData?.defaultLanguage as string;
}

/**
 * Returns supported languages based on selected partner and an optional partner
 */
export function getSupportedLanguages(selectedPartner: string, selectedRegion?: string): string[] {
  const partnerData = staticConfig.partners.find((partner) => partner.partnerType === selectedPartner);
  const supportedLanguagesSet: Set<string> = new Set<string>();

  let regions = partnerData?.regions;
  if (selectedRegion) {
    regions = regions?.filter((region) => region.regionName === selectedRegion);
  }

  regions
    ?.map((region) => region.supportedLanguages)
    .forEach((languages) => {
      languages.forEach((language) => {
        supportedLanguagesSet.add(language);
      });
    });

  return Array.from(supportedLanguagesSet);
}

/**
 * Returns supported regions based on selected partner
 */
export function getSupportedRegions(selectedPartner: string): string[] {
  const partnerData = staticConfig.partners.find((partner) => partner.partnerType === selectedPartner);
  return partnerData ? partnerData.regions.map((region) => region.regionName) : [];
}

/**
 * Returns supported regions based on selected partner
 */
export function getSupportedPartners(): string[] {
  return staticConfig.partners.map((partner) => partner.partnerType);
}

/**
 * Returns loyalty type based on selected partner/partnerRegion
 */
export function getLoyaltyType(selectedPartner: string, selectedPartnerRegion: string): string {
  const partnerData = staticConfig.partners.find((partner) => partner.partnerType === selectedPartner);
  const regionData = partnerData?.regions.find(
    (region) => region.regionName.toLowerCase() === (!selectedPartnerRegion ? '' : selectedPartnerRegion.toLowerCase()),
  );
  return regionData?.loyaltyType as string;
}

export function getAllSupportedLanguages(): string[] {
  const languages = staticConfig.partners.flatMap((p) => p.regions.flatMap((r) => r.supportedLanguages));
  return [...new Set(languages)];
}

/**
 * WARNING: Please do not use this function unless absolutely necessary.
 * In most scenarios, you should be able to leverage currentPartner and currentPartnerRegion
 * from the Application Context (ApplicationContext.tsx)
 * Highly preferred to only use this method when the user has not logged in yet
 * and we need to know which language to use for terms/privacy pages, etc.
 */
export function getPartnerAndPartnerRegionFromUrl(): {
  partnerFromUrl: string | null;
  partnerRegionFromUrl: RegionType | null;
} {
  const match = window.location.pathname.match(getSupportedPartnerRegionCombinationsAsRegex());
  if (match) {
    // If there's a match, it will be contained in the 'match' array
    // as an array of size 1, with a single string e.g. "uber-pro/us"
    const [_partner, _partnerRegion] = match[0].split('/');
    return {
      partnerFromUrl: _partner,
      partnerRegionFromUrl: _partnerRegion as RegionType,
    };
  } else {
    return {
      partnerFromUrl: null,
      partnerRegionFromUrl: null,
    };
  }
}
