import { getPartnerRegionConfig } from '../../helpers/PartnerRegionPropertySelector';

/**
 * Takes the audience value (JWT) and validates against IDP config.
 * @returns true if audience matches current parter and region.
 */

/* istanbul ignore next: No need to codecov */
export function hasAccessToRegion(userAudience: string, currentPartner: string, currentPartnerRegion: string): boolean {
  const partnerRegionConfig = getPartnerRegionConfig(currentPartner, currentPartnerRegion);
  return partnerRegionConfig && partnerRegionConfig.idpConfig.clientId === userAudience;
}

/**
 * Gets the authentication route for partner and region.
 * @returns string value.
 */
/* istanbul ignore next: No need to codecov */
export function getPartnerAuthenticationRoute(currentPartner: string, currentPartnerRegion: string): string {
  const redirectRoute = `/${currentPartner}/${currentPartnerRegion}/`;
  return redirectRoute;
}
