import { useMemo } from 'react';
import { AuthProvider } from '@marvel-common/ridehailing-idp';

import { useApplicationContext } from '../contexts/ApplicationContext';
import { useFeatureFlagsContext } from '../contexts/FeatureFlagsContext';
import { getPartnerRegionConfig } from '../helpers/PartnerRegionPropertySelector';
import { PartnerType } from '../interfaces/Partner';
import { RegionType } from '../helpers/RegionType';
import { FeatureFlagRegion, FeatureFlags } from '../interfaces/FeatureFlag';

export function getSuggestedAuthProvider(
  featureFlags: FeatureFlags,
  partner: PartnerType,
  region: RegionType,
): AuthProvider {
  try {
    if (!featureFlags?.[partner]) {
      return 'sf';
    }

    const authProvider = (featureFlags?.[partner] as FeatureFlagRegion)[region];
    const config = getPartnerRegionConfig(partner, region);

    return authProvider === 'cip' && config.cipConfig !== null ? 'cip' : 'sf';
  } catch (e) {
    return 'sf';
  }
}

export const useSuggestedAuthProvider = (): AuthProvider => {
  const { currentPartner, currentPartnerRegion } = useApplicationContext();
  const featureFlags = useFeatureFlagsContext();

  return useMemo(() => {
    return getSuggestedAuthProvider(featureFlags, currentPartner, currentPartnerRegion);
  }, [featureFlags, currentPartner, currentPartnerRegion]);
};
