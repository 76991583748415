import validatePhoneCIPMutation from 'graphql-tag';

export default validatePhoneCIPMutation`
mutation validatePhone($phoneInput: PhoneInput!) {
    validatePhone(phoneInput: $phoneInput) {
      countryCode
      phoneNumber
      region
      userId
      passcodeSessionId
    }
  }
`;

export type PhoneInput = {
  countryCode: string;
  phoneNumber: string;
  region: string;
  userId: string;
};
